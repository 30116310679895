import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../contexts/AuthContext";
import { TabDocuments } from "../components/ui/TabDocuments";

function Documents() {
  const { username } = useAuth();

  const profile = (
    <div className="App">
      <TabDocuments />
    </div>
  );
  return username !== null ? profile : <></>;
}

export default Documents;
