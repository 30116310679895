import { Button, Form, Modal } from "react-bootstrap";
import React, { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";

function Login() {
  const { token, username, login, logout } = useAuth();

  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [formValidated, setFormValidated] = useState<boolean>(false);

  const handleLogin = () => {
    setShowLogin(true);
  };
  const handleLogout = () => {
    logout();
  };
  const handleClose = () => setShowLogin(false);
  const formRef = useRef<HTMLFormElement>(null);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (formRef.current) {
      const formData = new FormData(formRef.current);
      const username = formData.get("username") as string;
      const password = formData.get("password") as string;
      setLoginError(null);
      try {
        login(username, password);
        handleClose();
      } catch (e) {
        if (e instanceof Error) {
          setLoginError(e.message);
        }
      }
    }

    setFormValidated(true);
  }

  return (
    <div>
      {token === null && (
        <>
          <Button onClick={handleLogin}>Log in</Button>
          <Modal show={showLogin} onHide={handleClose} keyboard={false}>
            <Form
              noValidate
              validated={formValidated}
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <Modal.Header closeButton>
                <Modal.Title>Log in to Anonymizer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Text className="text-muted">
                  If you want to sign up, send us an email to{" "}
                  <a href="mailto:info@substorm.ai">info@substorm.ai</a>
                </Form.Text>
                <Form.Group className="mb-3" controlId="formBasicUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    name="username"
                    type="text"
                    placeholder="Enter username"
                    autoFocus
                    autoComplete="username"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a password.
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                {loginError !== null && (
                  <p className="loginError">{loginError}</p>
                )}
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      )}
      {token !== null && (
        <>
          <span id="Login-Text-Header">
            Signed in as: <a href="/profile">{username}</a>
          </span>
          <Button onClick={handleLogout}>Logout</Button>
        </>
      )}
    </div>
  );
}

export default Login;
