import { Button, Card, Form, Stack } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { useSetTimeout } from "@wojtekmaj/react-hooks";
import { ProfileModal } from "./ProfileModal";
import axios from "axios";
import { apiUrl } from "../../config";

interface UserProfile {
  full_name: string;
  email: string;
  super_user: boolean;
}

interface UserInputOptional {
  username: string;
  password?: string;
  full_name?: string;
  email?: string;
  disabled?: boolean;
}

export function TabProfile() {
  const { username, token, reAuth } = useAuth();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [superUser, setSuperUser] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showChangeFullNameModal, setShowChangeFullNameModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [thirtyDaysFromNow, setThirtyDaysFromNow] = useState(new Date());
  const [inputFullName, setInputFullName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPassword2, setInputPassword2] = useState("");

  useEffect(() => {
    if (token !== null) {
      axios
        .get<UserProfile>(`${apiUrl}/user/info`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data)
        .then((data) => {
          setFullName(data.full_name);
          setInputFullName(data.full_name);
          setEmail(data.email);
          setInputEmail(data.email);
          setSuperUser(data.super_user);
        })
        .catch((e) => console.log(e));
    }
  }, [token]);

  function handleModifyValues(fields: UserInputOptional) {
    axios.post<UserProfile>(`${apiUrl}/user/modify`, fields, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  function handleChangeFullName() {
    setShowChangeFullNameModal(true);
  }

  function handleChangeEmail() {
    setShowChangeEmailModal(true);
  }

  function handleChangePassword() {
    setShowChangePasswordModal(true);
  }

  function handleDisable() {
    setShowDisableModal(true);
  }

  useSetTimeout(() => {
    setThirtyDaysFromNow(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000));
  }, 1000);

  return (
    <>
      <Card style={{ padding: "10px" }}>
        <Card.Header>
          <h1>Your information</h1>
        </Card.Header>
        <Stack gap={3} style={{ marginTop: "20px" }}>
          <Card>
            <Card.Body>
              <Card.Title>Username</Card.Title>
              <Card.Text>Your username is {username}.</Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Full name</Card.Title>
              <Card.Text>Your registered full name is {fullName}.</Card.Text>
              <Button
                style={{ alignSelf: "flex-start" }}
                onClick={handleChangeFullName}
              >
                Change full name
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Email</Card.Title>
              <Card.Text>Your registered email is {email}.</Card.Text>
              <Button
                style={{ alignSelf: "flex-start" }}
                onClick={handleChangeEmail}
              >
                Change email
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Super user</Card.Title>
              <Card.Text>
                You are <b>{superUser ? "" : "not"}</b> a super user
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Password</Card.Title>
              <Button
                style={{ alignSelf: "flex-start" }}
                onClick={handleChangePassword}
              >
                Change password
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Disable your account</Card.Title>
              <Card.Text>
                Your personal data and documents will be deleted after 30 days
                when disabling the account.
              </Card.Text>
              <Button
                style={{ alignSelf: "flex-start" }}
                variant="danger"
                onClick={handleDisable}
              >
                Disable your account
              </Button>
            </Card.Body>
          </Card>
        </Stack>
      </Card>
      <ProfileModal
        title="Are you sure you want to disable your account?"
        show={showDisableModal}
        setShow={setShowDisableModal}
        submitButtonText={"Disable the " + username + " account"}
        onSubmitButtonClick={() => {
          if (username === null) {
            throw Error("Username is not set");
          }
          handleModifyValues({ username, disabled: true });
          reAuth();
          setShowDisableModal(false);
        }}
      >
        <p>
          If you answer <b>yes</b> all your information will be deleted in 30
          days.
        </p>
        <p>
          You will have to contact us at{" "}
          <a href="mailto:info@substorm.ai">info@substorm.ai</a> if you want to
          reenable your account before {thirtyDaysFromNow.toDateString()}.
        </p>
      </ProfileModal>
      <ProfileModal
        title="Change your full name"
        show={showChangeFullNameModal}
        setShow={setShowChangeFullNameModal}
        submitButtonText="Apply"
        onSubmitButtonClick={() => {
          if (username === null) {
            throw Error("Username is not set");
          }
          handleModifyValues({ username, full_name: inputFullName });
          reAuth();
          setShowChangeFullNameModal(false);
        }}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>What is your new full name?</Form.Label>
            <Form.Control
              type="text"
              value={inputFullName}
              onChange={(e) => setInputFullName(e.target.value)}
            />
          </Form.Group>
        </Form>
      </ProfileModal>
      <ProfileModal
        title="Change your email"
        show={showChangeEmailModal}
        setShow={setShowChangeEmailModal}
        submitButtonText="Apply"
        onSubmitButtonClick={() => {
          if (username === null) {
            throw Error("Username is not set");
          }
          handleModifyValues({ username, email: inputEmail });
          reAuth();
          setShowChangeEmailModal(false);
        }}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>What is your new email?</Form.Label>
            <Form.Control
              type="email"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
            />
          </Form.Group>
        </Form>
      </ProfileModal>
      <ProfileModal
        title="Change your password"
        show={showChangePasswordModal}
        setShow={setShowChangePasswordModal}
        submitButtonText="Change password"
        onSubmitButtonClick={() => {
          if (username === null) {
            throw Error("Username is not set");
          }
          handleModifyValues({ username, password: inputPassword });
          reAuth();
          setShowChangePasswordModal(false);
        }}
      >
        <p>To change your password make sure that you enter it twice.</p>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>What is your new password?</Form.Label>
            <Form.Control
              type="password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Enter your password again</Form.Label>
            <Form.Control
              type="password"
              value={inputPassword2}
              onChange={(e) => setInputPassword2(e.target.value)}
            />
          </Form.Group>
        </Form>
      </ProfileModal>
    </>
  );
}
