// PdfToolbar.tsx
import React from "react";
import { Tooltip } from "bootstrap";
import { useCallback, useState, useEffect, useRef } from "react";
import ProcessQueue from "../../interfaces/ProcessQueue";
import ProcessQueueStatus from "../../enums/ProcessQueueStatus";
import axios from "axios";
import { EntitiesPackedInput } from "../../interfaces/EntitiesInput";
import {
  EntitiesResult,
  Group,
  PackedGroup,
} from "../../interfaces/EntitiesResult";
import {
  groupByRecognizer,
  findWordOccurrences,
  mergeDuplicateEntities,
} from "../../lib/words_lib";
import { apiUrl, pollingInterval } from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import type {
  TextContent,
  TextItem,
  TextMarkedContent,
} from "react-pdf/node_modules/pdfjs-dist/types/src/display/api";
import { UUID } from "crypto";
import type { PDFDocumentProxy } from "react-pdf/node_modules/pdfjs-dist";
import { Document, Page } from "react-pdf";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import useTextSelection from "./useTextSelection";
import { smartSplit } from "../../lib/words_split";
import { WordsStruct } from "../../lib/words_split";

interface props {
  allPagesRendered: boolean;
  OnSetAllPagesRendered: (c: boolean) => void;
  file: File | null;
  editMode: boolean;
  onRedact: boolean;
  groupsSortedMerged: { [key: string]: Group[] };
  setGroupsSortedMerged: React.Dispatch<
    React.SetStateAction<{ [key: string]: Group[] }>
  >;
  sidebarSelection: { recognizer: string; entity: string };
  componentRef: React.RefObject<HTMLDivElement>;
  triggerAddUserSelection: boolean;
}

// Sidebar component now accepts props of type SidebarProps
const PdfDocumentViewer: React.FC<props> = ({
  allPagesRendered,
  OnSetAllPagesRendered,
  file,
  onRedact,
  editMode,
  setGroupsSortedMerged,
  groupsSortedMerged,
  sidebarSelection,
  componentRef,
  triggerAddUserSelection,
}) => {
  const { username, token } = useAuth();
  const [groups, setGroups] = useState<{ [page_element_id: string]: Group[] }>(
    {}
  );
  const [tooltips, setTooltips] = useState<Tooltip[]>([]);
  const [highlightedWords, SetHighlightedWords] = useState<string[]>([]);
  const [redactedPdfUrl, SetRedactedPdfUrl] = useState<string | null>(null);
  const [pagesRendered, setPagesRendered] = useState<number[]>([]);
  const [numPages, setNumPages] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [inputToApiText, setInputToApiText] = useState<{
    [page_element_id: string]: string;
  }>({});

  useEffect(() => {
    if (groups !== null) {
      const group_result = groupByRecognizer(groups);
      const merged_group_result = mergeDuplicateEntities(group_result);
      setGroupsSortedMerged(merged_group_result);
    }
  }, [groups]);

  useEffect(() => {
    const highlighted_words = getWordsToHighlight(groupsSortedMerged);
    SetHighlightedWords(highlighted_words);
  }, [groupsSortedMerged]);

  useEffect(() => {
    if (pagesRendered.length === numPages) {
      OnSetAllPagesRendered(true);
    }
  }, [pagesRendered, numPages]);

  useEffect(() => {
    setPagesRendered([]);
    setGroups({});
  }, [file]);

  useEffect(() => {
    removeWordFromGroups(sidebarSelection.entity, sidebarSelection.recognizer);
  }, [sidebarSelection]);

  useEffect(() => {
    handleAddClicked();
  }, [triggerAddUserSelection]);

  const { selectedText, tooltipPosition, showTooltip, setShowTooltip } =
    useTextSelection(editMode, pdfContainerRef);

  type GroupsState = { [page_element_id: string]: Group[] };

  function removeWordFromGroups(word: string, entityRecognizer: string) {
    setGroups((prevGroups: GroupsState) => {
      const updatedGroups: GroupsState = {};

      for (const pageElementId in prevGroups) {
        const updatedGroup = prevGroups[pageElementId].map((group) => {
          if (group.recognizer === entityRecognizer) {
            return {
              ...group,
              entities: group.entities.filter(
                (entity) => entity.content !== word
              ),
            };
          }
          return group;
        });

        updatedGroups[pageElementId] = updatedGroup;
      }

      return updatedGroups;
    });
  }
  interface WordOccurences {
    word: string;
    position: Array<{
      pageIndex: string;
      occurrences: number[];
    }>;
  }
  const addUserSelectionToState = (data: WordOccurences) => {
    setGroups((prevGroups) => {
      const updatedGroups = { ...prevGroups };

      data.position.forEach(({ pageIndex, occurrences }) => {
        const groupIndex = updatedGroups[pageIndex]?.findIndex(
          (group) => group.recognizer === "USER_ADDED"
        );

        if (groupIndex !== undefined && groupIndex !== -1) {
          updatedGroups[pageIndex][groupIndex] = {
            ...updatedGroups[pageIndex][groupIndex],
            entities: [
              ...updatedGroups[pageIndex][groupIndex].entities,
              { content: data.word, occurrences },
            ],
          };
        } else {
          if (!updatedGroups[pageIndex]) {
            updatedGroups[pageIndex] = [];
          }
          updatedGroups[pageIndex].push({
            entities: [{ content: data.word, occurrences }],
            recognizer: "USER_ADDED",
          });
        }
      });

      return updatedGroups;
    });
  };
  const handleUserAddedEntity = (newSelection: string) => {
    const wordOccurrences = findWordOccurrences(inputToApiText, newSelection);
    addUserSelectionToState(wordOccurrences);
  };

  function getWordsToHighlight(groupsSortedMerged: {
    [key: string]: Group[];
  }): string[] {
    const allContents: string[] = [];

    Object.keys(groupsSortedMerged).forEach((key) => {
      groupsSortedMerged[key].forEach((group) => {
        group.entities.forEach((entity) => {
          allContents.push(entity.content);
        });
      });
    });

    return allContents;
  }

  const handleAddClicked = () => {
    if (selectedText) {
      handleUserAddedEntity(selectedText);
      SetHighlightedWords([...highlightedWords, selectedText]);
    }
  };

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, {}, onResize);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  async function analyzePackedText(
    input: EntitiesPackedInput
  ): Promise<PackedGroup> {
    const result = await axios.post<ProcessQueue>(
      `${apiUrl}/anonymiser/entities_packed`,
      input,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = result.data;
    const uuid = data.uuid;
    return (await pollJobStatus(uuid)) as PackedGroup;
  }

  async function analizePackedInput(input: {
    [key: string]: string;
  }): Promise<void> {
    const result = await analyzePackedText({ texts: input });

    setGroups((prevGroups) => ({ ...prevGroups, ...result }));
  }

  function isTextItem(item: TextItem | TextMarkedContent): item is TextItem {
    return (item as TextItem).str !== undefined;
  }

  const pollJobStatus = async (uuid: UUID): Promise<any> => {
    try {
      const response = await axios.get<EntitiesResult>(
        `${apiUrl}/anonymiser/result`,
        {
          params: {
            uuid: uuid,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data;

      if (data.status === ProcessQueueStatus.success) {
        return data.result;
      } else if (
        data.status === ProcessQueueStatus.pending ||
        data.status === ProcessQueueStatus.in_progress
      ) {
        // Return a new Promise that resolves after the polling interval
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            pollJobStatus(uuid).then(resolve).catch(reject);
          }, pollingInterval);
        });
      } else if (data.status === ProcessQueueStatus.failed) {
        throw new Error("Process got a failed status");
      } else {
        throw new Error(`Got an undefined status: ${data.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error; // Re-throw the error to be handled by the caller
    }
  };

  function onGetTextSuccess(textContent: TextContent, page: number): void {
    let inputAPI: { [key: string]: string } = {};
    textContent.items.forEach((x, i) => {
      if (isTextItem(x) && x.str.trim().length > 1) {
        // Pack text before sending them
        const text = x.str;
        inputAPI[`${page}_${i}`] = text;
      }
    });
    setInputToApiText((prevState) => ({
      ...prevState,
      ...inputAPI,
    }));
    analizePackedInput(inputAPI).then(() => {
      setPagesRendered((prevPages) => [...prevPages, page]);
    });
  }

  function getHTMLFromTextAndGroup(
    text: string,
    group: Group[],
    redacted: boolean
  ): string {
    const words = smartSplit(text);
    const wordsStruct = new WordsStruct(words, true);

    group.forEach((unit) => {
      const tag = unit.recognizer;
      const entities = unit.entities;
      if (entities !== undefined) {
        entities.forEach((ent) => {
          const occurences = ent.occurrences;
          const nWords = smartSplit(ent.content).length;
          if (occurences !== undefined) {
            occurences.forEach((o) => wordsStruct.setTags(tag, o, nWords));
          }
        });
      }
    });

    return wordsStruct.toString(redacted);
  }

  const textRenderer = useCallback(
    (textItem: { str: string; itemIndex: number; pageIndex: number }) => {
      if (allPagesRendered) {
        // Delete previous tooltips to avoid memory leaks
        tooltips.forEach((tooltip) => tooltip.disable());
        setTooltips([]);
        const group = groups[`${textItem.pageIndex}_${textItem.itemIndex}`];
        if (group !== undefined && group.length !== 0) {
          return getHTMLFromTextAndGroup(textItem.str, group, onRedact);
        } else {
          return textItem.str;
        }
      } else {
        return "";
      }
    },
    [allPagesRendered, groups, onRedact]
  );

  const onRenderTextLayerSuccess = useCallback(() => {
    if (allPagesRendered) {
      const tooltipTriggerList = Array.from(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        const tooltip = new Tooltip(tooltipTriggerEl as HTMLElement);
        setTooltips((oldTooltips) => [...oldTooltips, tooltip]);
      });
    }
  }, [allPagesRendered]);

  const pdfFile = redactedPdfUrl ? redactedPdfUrl : file;
  return (
    <div style={{ height: "100vh", overflowY: "auto" }} ref={setContainerRef}>
      <div ref={pdfContainerRef}>
        <div ref={componentRef}>
          <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={containerWidth}
                onGetTextSuccess={(textContent: TextContent) => {
                  onGetTextSuccess(textContent, index);
                }}
                customTextRenderer={textRenderer}
                onRenderTextLayerSuccess={onRenderTextLayerSuccess}
              />
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PdfDocumentViewer;
