import { Container, Col } from "react-bootstrap";
import axios from "axios";
import { Group } from "../interfaces/EntitiesResult";
import { apiUrl } from "../config";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useAuth } from "../contexts/AuthContext";
import "../App.css";
import { useLocation } from "react-router-dom";

import Sidebar from "../components/ui/Sidebar";
import PdfDocumentViewer from "../components/ui/PdfDocumentViewer";
import PdfToolbar from "../components/ui/PdfToolbar";
import AiSystemNotification from "../components/ui/AiSystemNotification";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface APIDocument {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Main = () => {
  const { username, token } = useAuth();
  const query = useQuery();
  const documentId = query.get("documentId");
  const [file, setFile] = useState<File | null>(null);

  const [groupsSortedMerged, setGroupsSortedMerged] = useState<{
    [key: string]: Group[];
  }>({});
  const [pagesRendered, setPagesRendered] = useState<number[]>([]);
  const [allPagesRendered, setAllPagesRendered] = useState<boolean>(false);
  const componentRef = useRef(null);
  const [redacted, setRedacted] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [triggerAddUserSelection, setTriggerAddUserSelection] =
    useState<boolean>(false);

  const [sidebarSelection, setSidebarSelection] = useState<{
    recognizer: string;
    entity: string;
  }>({
    recognizer: "",
    entity: "",
  });

  useEffect(() => {
    if (documentId !== null && token !== null) {
      const url = `${apiUrl}/anonymiser/document/${username}/${documentId}`;
      axios
        .get(url, {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setFile(
            new File([response.data], documentId, {
              type: response.data.type,
            })
          );
        });
    }
  }, [token, documentId]);

  const handleSidebarClick = (inputValue: {
    recognizer: string;
    entity: string;
  }) => {
    setSidebarSelection(inputValue);

    const updatedGroup = Object.keys(groupsSortedMerged).reduce((acc, key) => {
      if (key === inputValue.recognizer) {
        acc[key] = groupsSortedMerged[key].map((item: any) => ({
          ...item,
          entities: item.entities.filter(
            (entity: any) => entity.content !== inputValue.entity
          ),
        }));
      } else {
        acc[key] = groupsSortedMerged[key];
      }
      return acc;
    }, {} as { [key: string]: Group[] });

    setGroupsSortedMerged(updatedGroup);
  };

  useEffect(() => {
    setPagesRendered([]);
  }, [file]);

  const handleRedact = () => {
    setRedacted((prevRedacted) => !prevRedacted);
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode((prevEditMode) => !prevEditMode);
  };

  const handleAllPagesRendered = (c: boolean) => {
    setAllPagesRendered(c);
  };

  const handleAddedSelection = () => {
    setTriggerAddUserSelection((prev) => !prev);
  };

  return (
    <>
      <Sidebar
        data={groupsSortedMerged ?? []}
        onItemClick={handleSidebarClick}
      />
      <Col xs={9} className="main-content">
        <Container>
          <PdfToolbar
            allPagesRendered={allPagesRendered}
            onHandleEdit={handleEdit}
            editMode={editMode}
            onHandleRedact={handleRedact}
            onRedact={redacted}
            file={file}
            componentRef={componentRef}
            onAddSelectedText={handleAddedSelection}
          />
          <PdfDocumentViewer
            allPagesRendered={allPagesRendered}
            OnSetAllPagesRendered={handleAllPagesRendered}
            file={file}
            editMode={editMode}
            onRedact={redacted}
            setGroupsSortedMerged={setGroupsSortedMerged}
            groupsSortedMerged={groupsSortedMerged}
            sidebarSelection={sidebarSelection}
            componentRef={componentRef}
            triggerAddUserSelection={triggerAddUserSelection}
          />{" "}
          {file === null && <AiSystemNotification />}
        </Container>
      </Col>
    </>
  );
};

export default Main;
