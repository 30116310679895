import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../contexts/AuthContext";
import { TabProfile } from "../components/ui/TabProfile";

function Profile() {
  const { username } = useAuth();

  const profile = (
    <div className="App">
      <TabProfile />
    </div>
  );
  return username !== null ? profile : <></>;
}

export default Profile;
