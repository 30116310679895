import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import Cookies from "js-cookie";
import { decodeToken, isExpired } from "react-jwt";
import DecodedJWT from "../interfaces/DecodedJWT";
import { apiUrl } from "../config";
import axios from "axios";
import Token from "../interfaces/Token";

interface AuthContextType {
  token: string | null;
  username: string | null;
  expired: boolean | null;
  login: (username: string, password: string) => void;
  logout: () => void;
  reAuth: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [token, setTokenState] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [expired, setExpired] = useState<boolean | null>(null);

  useEffect(() => {
    const savedToken = Cookies.get("authToken");
    if (savedToken !== undefined) {
      setTokenState(savedToken);
    }
  }, []);

  useEffect(() => {
    setUsername(null);
    setExpired(null);
    if (token !== null) {
      let decoded = decodeToken<DecodedJWT>(token);
      if (decoded !== null) {
        setUsername(decoded.sub);
        setExpired(isExpired(token));
      }
    }
  }, [token]);

  useEffect(() => {
    if (expired) {
      logout();
    }
  }, [expired]);

  const setToken = (newToken: string) => {
    setTokenState(newToken);
    Cookies.set("authToken", newToken, { expires: 7 }); // Expires in 7 days
  };

  const reAuth = () => {
    if (token) {
      setToken(token);
    }
  };

  const login = (username: string, password: string) => {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    axios
      .post<Token>(`${apiUrl}/login`, params)
      .then((response) => response.data)
      .then((data) => {
        setToken(data.access_token);
      })
      .catch((error: Error) => {
        throw error;
      });
  };

  const logout = () => {
    setTokenState(null);
    Cookies.remove("authToken");
  };

  return (
    <AuthContext.Provider
      value={{ token, username, expired, login, logout, reAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
