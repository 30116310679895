import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Login from "./Login";

function ColorSchemesExample() {
  return (
    <>
      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/">Anonymizer</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Col>
              <Nav className="ms-auto">
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>{" "}
                <Nav.Link as={Link} to="/pta">
                  Plain Text Anonymizer
                </Nav.Link>
                <Nav.Link as={Link} to="/documents">
                  Stored Documents
                </Nav.Link>
              </Nav>
            </Col>
            <Col id="Login-info">
              <Navbar.Text>
                <Login />
              </Navbar.Text>
            </Col>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorSchemesExample;
