const AiSystemNotification = () => {
  return (
    <div className="ai-system-info">
      <p>
        Kindly note that you are engaging with an AI-powered application. We do
        not assume responsibility for any instances where automatic
        anonymization may fail to remove names or other sensitive information.
        Users are advised to independently verify all results for accuracy.
      </p>
    </div>
  );
};

export default AiSystemNotification;
