import React, { useState, useEffect, FormEvent } from "react";
import { Container, Form, Button } from "react-bootstrap";
import axios from "axios";
import { apiUrl } from "../../config";
import ProcessQueueStatus from "../../enums/ProcessQueueStatus";
import { UUID } from "crypto";
import { EntitiesResult, Group } from "../../interfaces/EntitiesResult";
import ProcessQueue from "../../interfaces/ProcessQueue";
import { useAuth } from "../../contexts/AuthContext";
import { WordsStruct, smartSplit } from "../../lib/words_split";

const POLLING_INTERVAL = 2000; // Polling interval in milliseconds

const PlainTextAnonymizer: React.FC = () => {
  const [jobId, setJobId] = useState<string | null>(null);
  const [status, setStatus] = useState<ProcessQueueStatus>(
    ProcessQueueStatus.pending
  );
  const [result, setResult] = useState<Group[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [anoynomizedText, setAnonymizedText] = useState<string>("");
  const [analyzedText, setAnalyzedText] = useState<string>("");
  const [inputText, setInputText] = useState<string>("");
  const [foundEntities, setFoundEntities] = useState<string[]>([]);
  const { token } = useAuth();

  useEffect(() => {
    console.log(result);
    const words = smartSplit(inputText);
    const wordsStruct = new WordsStruct(words, false);
    result.forEach((unit) => {
      const tag = unit.recognizer;
      const entities = unit.entities;
      if (entities !== undefined) {
        entities.forEach((ent) => {
          const occurences = ent.occurrences;
          const nWords = smartSplit(ent.content).length;
          if (occurences !== undefined) {
            occurences.forEach((o) => wordsStruct.setTags(tag, o, nWords));
          }
        });
      }
    });
    setFoundEntities(result.map((group) => group.recognizer));
    setAnonymizedText(wordsStruct.toString(true));
    setAnalyzedText(wordsStruct.toString(false));
  }, [result]);

  const pollJobStatus = async (uuid: UUID): Promise<void> => {
    try {
      const response = await axios.get<EntitiesResult>(
        `${apiUrl}/anonymiser/result`,
        {
          params: {
            uuid: uuid,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const { status, result: _result } = response.data;

      console.log(_result);
      setStatus(status);

      if (status === ProcessQueueStatus.success) {
        setResult(_result);
      } else if (
        status === ProcessQueueStatus.pending ||
        status === ProcessQueueStatus.in_progress
      ) {
        setTimeout(() => pollJobStatus(uuid), POLLING_INTERVAL);
      } else if (status === ProcessQueueStatus.failed) {
        throw new Error("Process got a failed status");
      } else {
        throw new Error(`Got an undefined status: ${status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to fetch the job status.");
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);
    axios
      .post<ProcessQueue>(
        `${apiUrl}/anonymiser/entities`,
        {
          text: inputText,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => response.data)
      .then((data) => {
        setJobId(data.uuid);
        setStatus(ProcessQueueStatus.pending);
        pollJobStatus(data.uuid);
      });
  };

  return (
    <Container className="mt-5">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicText">
          <Form.Label>Input Text</Form.Label>
          <Form.Control
            name="input_text"
            type="text"
            placeholder="Enter text to anonymize"
            as="textarea"
            rows={3}
            value={inputText}
            onChange={(e) => setInputText(e.currentTarget.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-2">
          Process
        </Button>
      </Form>
      <Form.Group controlId="formResultText" className="mt-3">
        <Form.Label>Analyzed text</Form.Label>
        <Form.Control as="textarea" rows={3} value={analyzedText} readOnly />
      </Form.Group>
      <Form.Group controlId="formFoundEntities" className="mt-3">
        <Form.Label>Found entities</Form.Label>
        <Form.Control
          as="textarea"
          rows={1}
          value={foundEntities.join(", ")}
          readOnly
        />
      </Form.Group>
      <Form.Group controlId="formResultText" className="mt-3">
        <Form.Label>Redacted text</Form.Label>
        <Form.Control as="textarea" rows={3} value={anoynomizedText} readOnly />
      </Form.Group>
    </Container>
  );
};

export default PlainTextAnonymizer;
