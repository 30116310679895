export const tagColor: { [key: string]: string } = {
  CREDIT_CARD: "green",
  DATE_TIME: "green",
  EMAIL_ADDRESS: "blue",
  EXTENDED_PHONE_NUMBER: "green",
  IBAN_CODE: "green",
  INITIALS: "green",
  IP_ADDRESS: "green",
  LOCATION: "green",
  NAME: "orange",
  ORGANIZATION: "red",
  PERSON: "green",
  PHONE_NUMBER: "green",
  SWEDISH_BOX_ADDRESS: "green",
  SWEDISH_COMPANY_NAME: "green",
  SWEDISH_DATE: "green",
  SWEDISH_ORGANISATIONAL_NUMBER: "yellow",
  SWEDISH_POSTAL_ADDRESS: "green",
  SWEDISH_SOCIAL_NUMBER: "green",
  USER_ADDED: "red",
};
