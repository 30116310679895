import { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

export function ProfileModal(props: {
  title: string;
  show: boolean;
  setShow: (value: boolean) => void;
  submitButtonText: string;
  onSubmitButtonClick: () => void;
  children: ReactNode;
}) {
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setShow(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={props.onSubmitButtonClick}>
          {props.submitButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
