import { tagColor } from "../constants";

export class WordsStruct {
  words: string[];
  recognizers: string[];
  entities: number[];
  entityCounter: number;
  outputHTML: boolean;

  constructor(words: string[], outputInHTML = false) {
    this.words = words;
    this.recognizers = new Array(words.length).fill(null);
    this.entities = new Array(words.length).fill(null);
    this.entityCounter = 0;
    this.outputHTML = outputInHTML;
  }

  constructEntityWordAndAddToReturn(
    tag: string | null,
    entityWord: string[],
    result: string[],
    redacted: boolean = false
  ): void {
    let constructedWord = entityWord.join(" ");

    if (tag === null && this.outputHTML === true) {
      //constructedWord = `<span style="position:relative;color:black;cursor:default;user-select:auto">${constructedWord}</span>`;
    } else if (tag === null && this.outputHTML === false) {
      // Constructed word is already what we need
    } else if (redacted && this.outputHTML === true) {
      // Given the fonts used by the PDF renderer, the characters are variable sized
      // so replacing the letters with anything might introduce aligning issues
      // Using 1's as character to redact with since it works OK for now. A better solution might be necessary.
      constructedWord = `<span style="background-color:black;position:relative;color:transparent;cursor:default;user-select:none">${"1".repeat(
        constructedWord.length
      )}</span>`;
    } else if (redacted && this.outputHTML === false) {
      constructedWord = `<${tag}>`;
    } else if (tag !== null && this.outputHTML === true) {
      constructedWord = `<span class="${tagColor[tag]}-tag" style="position:relative;cursor:help;color:transparent;user-select:none" data-bs-toggle='tooltip' data-bs-placement="top" data-bs-title="${tag}" data-bs-custom-class="${tagColor[tag]}-tooltip">${constructedWord}</span>`;
    } else if (tag !== null && this.outputHTML === false) {
      constructedWord = `<${tag}>${constructedWord}</${tag}>`;
    }
    result.push(constructedWord);
  }

  toString(redacted: boolean = false) {
    let result: string[] = [];
    let previousEntity: number | null = null;
    let entityWord: string[] = [];
    let tag: string | null = null;

    for (let i = 0; i < this.words.length; i++) {
      let word = this.words[i];
      let entity = this.entities[i];

      if (previousEntity === null && entity === null) {
        // Case 1: Both are null, we are outside any entity
        entityWord.push(word);
        tag = this.recognizers[i];
        this.constructEntityWordAndAddToReturn(
          tag,
          entityWord,
          result,
          redacted
        );
        entityWord = [];
      } else if (previousEntity !== null && entity === null) {
        // Case 2: Exiting an entity

        entityWord.push(word);
        tag = this.recognizers[i];
        this.constructEntityWordAndAddToReturn(
          tag,
          entityWord,
          result,
          redacted
        );
        entityWord = [];
      } else if (previousEntity === null && entity != null) {
        // Case 3: Entering a new entity
        // entityWord = [word];

        entityWord.push(word);
        tag = this.recognizers[i];
        this.constructEntityWordAndAddToReturn(
          tag,
          entityWord,
          result,
          redacted
        );
        entityWord = [];
      } else if (
        previousEntity !== null &&
        entity !== null &&
        previousEntity !== entity
      ) {
        // Case 4: Switching entities
        entityWord = [word];
        tag = this.recognizers[i];
        this.constructEntityWordAndAddToReturn(
          tag,
          entityWord,
          result,
          redacted
        );
        entityWord = [];
      } else if (
        previousEntity !== null &&
        entity !== null &&
        previousEntity === entity
      ) {
        // Case 5: Continuing within the same entity

        entityWord.push(word);
      } else {
        throw Error("We are in an impossible state");
      }

      previousEntity = entity;
    }

    // Finalize any remaining words in entityWord after the loop ends
    if (entityWord.length > 0) {
      this.constructEntityWordAndAddToReturn(tag, entityWord, result, redacted);
    }

    return result.join(" ");
  }

  setTags(tag: string, index: number, nWords: number) {
    for (let i = 0; i < nWords; i++) {
      this.recognizers[index + i] = tag;
      this.entities[index + i] = this.entityCounter;
    }
    this.entityCounter++;
  }
}

export function smartSplit(
  input: string,
  separator?: string | RegExp,
  maxsplit?: number
): string[] {
  // Default behavior for whitespace splitting
  if (separator === undefined) {
    // Split on any whitespace and remove empty strings
    const parts = input.trim().split(/\s+/);
    return parts;
  }

  // If a separator is provided
  let parts: string[];
  if (typeof separator === "string") {
    parts = input.split(separator);
  } else {
    parts = input.split(separator);
  }

  // Handle maxsplit if provided
  if (maxsplit !== undefined && maxsplit >= 0) {
    const limitedParts = parts.slice(0, maxsplit);
    const remainder = parts
      .slice(maxsplit)
      .join(separator instanceof RegExp ? "" : separator);
    if (remainder) {
      limitedParts.push(remainder);
    }
    return limitedParts;
  }

  return parts;
}
