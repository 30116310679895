import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PlainTextAnonymizer from "../components/ui/PlainTextAnonymizer";

function PTA() {
  return (
    <div className="App">
      <PlainTextAnonymizer />
    </div>
  );
}

export default PTA;
