import React from "react";
import { Col } from "react-bootstrap";
import "../../App.css";
import { SidebarGroup } from "../../interfaces/EntitiesResult";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface SideBarGroupProps {
  data: SidebarGroup;
  onItemClick: (inputValue: { recognizer: string; entity: string }) => void; // Add the callback prop type
}

const Sidebar: React.FC<SideBarGroupProps> = ({ data, onItemClick }) => {
  return (
    <Col xs="auto" md={3} xl={2} className="sidebar bg-light">
      <div className="sidebar-content">
        <h4>Found Entities:</h4>
        {Object.entries(data).map(([recognizer, items], recognizerIndex) => (
          <div className="sidebar-content-entity" key={recognizerIndex}>
            <h6>{recognizer}</h6>
            {items.map((item, itemIndex) => (
              <div className="sidebar-content-entity-result" key={itemIndex}>
                {item.entities.map((entity, entityIndex) => (
                  <ul key={`${recognizerIndex}-${itemIndex}-${entityIndex}`}>
                    <li className="hoverable-item">
                      <span>
                        {entity.content} ({entity.occurrences.length})
                      </span>
                      <span
                        className="trash-icon"
                        onClick={() =>
                          onItemClick({
                            recognizer: recognizer,
                            entity: entity.content,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Col>
  );
};

export default Sidebar;
