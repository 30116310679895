// PdfToolbar.tsx
import React, { useState } from "react";
import { Stack, Button, Form, Spinner, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faEdit,
  faPlay,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { apiUrl } from "../../config";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useReactToPrint } from "react-to-print";

interface props {
  allPagesRendered: boolean;
  onHandleEdit: () => void;
  editMode: boolean;
  onHandleRedact: () => void;
  onRedact: boolean;
  file: File | null;
  componentRef: React.RefObject<HTMLDivElement>;
  onAddSelectedText: () => void;
}

interface APIDocument {
  id: string;
}

const PdfToolbar: React.FC<props> = ({
  allPagesRendered,
  onHandleEdit,
  editMode,
  onRedact,
  onHandleRedact,
  file,
  componentRef,
  onAddSelectedText,
}) => {
  const { username, token } = useAuth();
  const fileSelector = useRef<HTMLInputElement | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
  const navigate = useNavigate();

  function uploadFile(file: File) {
    setIsAnalyzing(true);
    const data = new FormData();
    data.append("file", file);
    axios
      .post<APIDocument>(`${apiUrl}/anonymiser/upload_document`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data)
      .then((data) => {
        navigate(`/?documentId=${data.id}`);
      })
      .catch((error) => console.log(error));
    setIsAnalyzing(false);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Analyzed " + file?.name ?? "document",
  });

  const onFileChange = () => {
    if (
      fileSelector.current !== null &&
      fileSelector.current.files !== null &&
      fileSelector.current.files.length > 0
    ) {
      console.log("fileselector: ", fileSelector.current.files[0]);
      uploadFile(fileSelector.current.files[0]);
    }
  };

  return (
    <Container className="my-2">
      <Stack direction="horizontal" gap={3}>
        {allPagesRendered && (
          <Button
            variant="dark"
            style={{ width: "160px" }}
            onClick={handlePrint}
          >
            <FontAwesomeIcon icon={faPrint} /> Print
          </Button>
        )}
        <Form.Control
          type="file"
          onChange={onFileChange}
          accept="application/pdf"
          ref={fileSelector}
          disabled={username === null}
        />
        {allPagesRendered && (
          <Button
            variant="dark"
            onClick={() => onAddSelectedText()}
            disabled={!editMode}
          >
            Add
          </Button>
        )}
        {allPagesRendered && (
          <Button
            variant="dark"
            style={{ width: "160px" }}
            onClick={onHandleEdit}
          >
            <FontAwesomeIcon icon={faEdit} />
            {editMode ? " Stop" : " Edit"}
          </Button>
        )}

        {(allPagesRendered && !onRedact && (
          <Button
            variant="dark"
            style={{ width: "160px" }}
            onClick={onHandleRedact}
          >
            <FontAwesomeIcon icon={faPlay} /> Redact
          </Button>
        )) ||
          (allPagesRendered && onRedact && (
            <Button
              variant="dark"
              style={{ width: "160px" }}
              onClick={onHandleRedact}
            >
              <FontAwesomeIcon icon={faStop} /> Unredact
            </Button>
          )) ||
          (isAnalyzing && (
            <Button variant="dark" style={{ width: "280px" }} disabled>
              <Spinner animation="border" variant="success" size="sm" />{" "}
              Analyzing document
            </Button>
          ))}
      </Stack>
    </Container>
  );
};

export default PdfToolbar;
