import { Button, Card, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTrash } from "@fortawesome/free-solid-svg-icons";

interface Document {
  id: string;
}

export function TabDocuments() {
  const { token, username } = useAuth();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (token !== null || reload !== false) {
      axios
        .get<Document[]>(`${apiUrl}/anonymiser/documents`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data)
        .then((data) => setDocuments(data))
        .catch((e) => console.log(e));
      setReload(false);
    }
  }, [token, reload]);

  function handleDelete(documentId: string) {
    axios
      .delete(`${apiUrl}/anonymiser/document/${username}/${documentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setReload(true);
        }
      })
      .catch((e) => console.log(e));
  }

  return (
    <Card style={{ padding: "10px" }}>
      <Card.Header>
        <h1>Your stored documents</h1>
      </Card.Header>
      <Card.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Open</th>
              <th>File Name</th>
              <th>Upload time</th>
              <th>Delete file</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document, i) => {
              const year = parseInt(document.id.substring(0, 4));
              const month = parseInt(document.id.substring(4, 6));
              const day = parseInt(document.id.substring(6, 8));
              const hour = parseInt(document.id.substring(8, 10));
              const minute = parseInt(document.id.substring(10, 12));
              const second = parseInt(document.id.substring(12, 14));
              return (
                <tr key={i}>
                  <td>
                    <Button href={`/?documentId=${document.id}`}>
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Button>
                  </td>
                  <td>
                    <div>{document.id.substring(21)}</div>
                  </td>
                  <td>
                    {new Date(
                      year,
                      month,
                      day,
                      hour,
                      minute,
                      second
                    ).toLocaleString("sv-SE")}
                  </td>
                  <td>
                    <div>
                      <OverlayTrigger
                        trigger="click"
                        placement="bottom-end"
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Header as="h3">
                              Are you absolutely sure?
                            </Popover.Header>
                            <Popover.Body>
                              Your document will be <b>irreversibly deleted</b>{" "}
                              from our server.
                              <br />
                              Are you sure you wish to continue?{" "}
                              <Button
                                variant="outline-danger"
                                onClick={() => handleDelete(document.id)}
                              >
                                Continue
                              </Button>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button variant="outline-danger">
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
