import "./App.css";
import Main from "./routes/Main";
import Layout from "./components/layouts/Layout";
import PTA from "./routes/PTA";
import Profile from "./routes/Profile";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Documents from "./routes/Documents";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="/pta" element={<PTA />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/documents" element={<Documents />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
