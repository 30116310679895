import { useEffect, useState, RefObject } from "react";

// Define types for the tooltip position state
type TooltipPosition = {
  x: number;
  y: number;
};

// Define the return type of the custom hook
type UseTextSelectionResult = {
  selectedText: string;
  tooltipPosition: TooltipPosition;
  showTooltip: boolean;
  setShowTooltip: React.Dispatch<React.SetStateAction<boolean>>;
};

// Custom hook to manage text selection and tooltip visibility
const useTextSelection = (
  editMode: boolean,
  pdfContainerRef: RefObject<HTMLDivElement>
): UseTextSelectionResult => {
  const [selectedText, setSelectedText] = useState<string>(""); // State for selected text
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    x: 0,
    y: 0,
  }); // State for tooltip position
  const [showTooltip, setShowTooltip] = useState<boolean>(false); // State for tooltip visibility

  useEffect(() => {
    if (!editMode) {
      return; // Exit if edit mode is disabled
    }

    const handleMouseUp = (e: MouseEvent) => {
      if (pdfContainerRef.current) {
        const selection = window.getSelection(); // Get the current text selection
        if (selection && selection.toString().trim() !== "") {
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect(); // Get the bounding rectangle of the selection

          const containerRect = pdfContainerRef.current.getBoundingClientRect(); // Get the bounding rectangle of the container
          if (
            rect.top >= containerRect.top &&
            rect.left >= containerRect.left &&
            rect.bottom <= containerRect.bottom &&
            rect.right <= containerRect.right
          ) {
            setSelectedText(selection.toString()); // Update the selected text state
            setTooltipPosition({ x: rect.left, y: rect.top - 30 }); // Update the tooltip position state
            setShowTooltip(true); // Show the tooltip
          } else {
            setShowTooltip(false); // Hide the tooltip if selection is outside
          }
        } else {
          setShowTooltip(false); // Hide the tooltip if no text is selected
        }
      }
    };

    // Add mouseup event listener to the document
    document.addEventListener("mouseup", handleMouseUp);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [editMode, pdfContainerRef]); // Dependencies: editMode and pdfContainerRef

  return { selectedText, tooltipPosition, showTooltip, setShowTooltip }; // Return the hook result
};

export default useTextSelection;
